<template>
  <div>
    <b-table
      ref="away-entitlement-page-dt"
      id="away-entitlement-page-dt"
      table-class="table-head-custom table-vertical-center mt-5"
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :responsive="true"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showEntitledAwayApplication(data)"
            v-b-tooltip.hover="'Apply Away'"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
            </span>
          </button>

          <!-- end::: edit-employee button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No away created by the school"
          text-bottom="Cannot apply any away status"
        ></AppEmptyList>
      </template>
    </b-table>

    <PersonalAwayEntitlementModal
      :form="form"
      @formSubmitted="handleFormSubmitted"
    ></PersonalAwayEntitlementModal>

    <div class="d-flex justify-content-end" v-if="items.length || !isBusy">
      <b-pagination
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        size="lg"
        pills
        aria-controls="away-entitlement-page-dt"
        @change="handlePageChange"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import PersonalAwayEntitlementModal from "@/modules/personal/components/away/entitlement/modal/PersonalAwayEntitlementModal";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";

export default {
  name: "PersonalAwayEntitlementTable",
  components: { AppEmptyList, PersonalAwayEntitlementModal, AppLoader },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: "Away Type" },
        { key: "abbreviation", label: "Abbreviation" },
        { key: "actions", label: this.$t("BUTTON.APPLY") },
      ],
      isBusy: true,
    };
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchPersonalAwayEntitlementList")
        .finally(() => (this.isBusy = false));
    },

    handlePageChange(value) {
      console.log(value);
      console.log("Fetching ...");
      this.$store.commit("setPersonalAwayEntitlementListPage", value);
      this.fetch();
    },

    /**
     * Handle form submitted.
     *
     * @param res
     */
    handleFormSubmitted(res) {
      console.log(res);

      this.fetch();
      this.$bvModal.hide("personal-away-entitlement-modal");
    },

    /**
     * Show leave application.
     *
     * @param data
     */
    showEntitledAwayApplication(data) {
      console.log("Selected away ", data.item);

      this.$store.dispatch("assignEntitledAwayApplication", data.item);
      this.$store.dispatch("fetchPersonalAwayEntitlementForm", {
        id: data.item.id,
      });

      this.$bvModal.show("personal-away-entitlement-modal");
    },
  },

  computed: {
    ...mapGetters({
      items: "getPersonalAwayEntitlementList",
      pagination: "getPersonalAwayEntitlementListPagination",
      form: "getPersonalAwayEntitlementForm",
    }),
  },
};
</script>

<style scoped></style>
